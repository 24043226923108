import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { DirectionActivity } from 'src/app/models/portfolio/directionActivity.model';
import { LKPortfolio } from 'src/app/models/portfolio/portfolio.model';
import { ScanFile } from 'src/app/models/portfolio/scanFile.model';
import { LKStudent } from 'src/app/models/profile/lkstudent.model';
import { LKStudPerson } from 'src/app/models/profile/lkStudPerson.model';
import { DirectionActivityService } from 'src/app/services/LKPortfolio/direction-activity.service';
import { LKPortfolioService } from 'src/app/services/LKPortfolio/lkportfolio.service';
import { LKStudentService } from 'src/app/services/LKStudent/lkstudent.service';
import { NotificationsService } from 'src/app/services/Notifications/notifications.service';

@Component({
  selector: 'portfolio-add-form',
  templateUrl: './portfolio-add-form.component.html',
  styleUrls: ['./portfolio-add-form.component.scss']
})
export class PortfolioAddFormComponent implements OnInit {

  public fileSelected = false;

  public restrictions: FileRestrictions = {
    minFileSize: 10,
    maxFileSize: 4194304,
    allowedExtensions: [".pdf"],
  };

  public checked = false;
  public active = false;
  private editedRowIndex: number | undefined;
  public max: Date = new Date();
  public uploads!: Array<any>;

  // StudPerson
  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  // Student
  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "",
    studPersonId: "",
    studPerson: this.studPerson
  }

  public scanFiles!: ScanFile[];
  public scanFile: ScanFile = {
    fileName: "",
  }

  public portfolios!: LKPortfolio[];
  public portfolio: LKPortfolio = {
    portfolioId: 0,
    studentId: "",
    portfolioExternalId: "",
    directionActivityId: "",
    status: "",
    name: "",
    condition: "",
    subCondition: "",
    marksWithCondition: "",
    //eventDate: new Date(),
    //expirationDate: new Date(),
    notes: "",
    review: false,
    scanFile: this.scanFiles
  }

  public directionActivities!: DirectionActivity[];
  public directionActivity: DirectionActivity = {
    directionActivityId: 0,
    directionActivityExternalId: "",
    name: "",
    portfolio: this.portfolios
  }

  constructor(private router: Router,
    private portfolioService: LKPortfolioService,
    private directionActivitiesService: DirectionActivityService,
    private notificationsService: NotificationsService,
    private studentService: LKStudentService) { }

  ngOnInit(): void {
    this.getDirectionActivities();
    this.getStudentByLogin("123456")
  }

  public editForm: UntypedFormGroup = new UntypedFormGroup({
    portfolioId: new UntypedFormControl(),
    studentId: new UntypedFormControl(),
    portfolioExternalId: new UntypedFormControl(),
    directionActivityExternalId: new UntypedFormControl(),
    status: new UntypedFormControl(),
    name: new UntypedFormControl(),
    meetsScholarshipRequirement: new UntypedFormControl(),
    condition: new UntypedFormControl(),
    subCondition: new UntypedFormControl(),
    marksWithCondition: new UntypedFormControl(),
    eventDate: new UntypedFormControl(),
    expirationDate: new UntypedFormControl(),
    notes: new UntypedFormControl(),
    review: new UntypedFormControl(),
    scanFile: new UntypedFormGroup({
      file: new UntypedFormControl(),
      fileName: new UntypedFormControl()
    })
  });

  @Input() public isNew = false;

  @Input() public set model(lkPortfolio: LKPortfolio) {
    this.editForm.reset(lkPortfolio);

    // toggle the Dialog visibility
    this.active = lkPortfolio !== undefined;
  }

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() save: EventEmitter<LKPortfolio> = new EventEmitter();

  // Сохранить заявку
  public saveActivity() {
    if(this.editForm.value.meetsScholarshipRequirement == null)
    {
      this.editForm.value.meetsScholarshipRequirement = false;
    }

    // В текущей версии пока присваиваем это значение к false
    this.editForm.value.meetsScholarshipRequirement = false;

    var formData = new FormData();
    var eventDate = (new Date(this.editForm.value.eventDate)).toUTCString();
    var expirationDate = (new Date(this.editForm.value.expirationDate)).toUTCString();

    this.editForm.value.review = false;
    this.editForm.value.status = 1;
    this.editForm.value.studentId = this.studentModel.externalId;

    formData.append(`DirectionActivityExternalId`, this.editForm.value.directionActivityExternalId)
    formData.append(`StudentId`, this.editForm.value.studentId)
    formData.append(`Status`, this.editForm.value.status)
    formData.append(`Name`, this.editForm.value.name)
    formData.append(`MeetsScholarshipRequirement`, this.editForm.value.meetsScholarshipRequirement)
    formData.append('Condition', this.editForm.value.condition)
    formData.append(`SubCondition`, this.editForm.value.subCondition)
    formData.append(`MarksWithCondition`, this.editForm.value.marksWithCondition)
    formData.append(`EventDate`, eventDate)
    formData.append(`ExpirationDate`, expirationDate)
    formData.append(`Notes`, this.editForm.value.notes)
    formData.append(`Review`, this.editForm.value.review)

    if (this.uploads != undefined) {
      for (let i = 0; i < this.uploads.length; i++) {
          formData.append(`ScanFile[${i}].FileName`, this.uploads[i].name);
          formData.append(`ScanFile[${i}].File`, this.uploads[i], this.uploads[i].name);
      }
    }
    this.portfolioService.addLKPortfolio(formData)
      .subscribe(
        response => {
          this.notificationsService.showSuccess("Достижение успешно добавлено!");
          this.onCancel();
        },
        error => {
          this.notificationsService.showError('Не удалось добавить достижение');
        }
      );
  }

  //Getting student by login
  public getStudentByLogin(login: string) {
    this.studentService.getStudentByLogin()
      .subscribe(
        response => {
          this.students = response;
          this.studentModel = response[0];
          //console.log(this.studentModel);
        }
      );
  }

  public getDirectionActivities() {
    this.directionActivitiesService.getAllDirectionActivities()
      .subscribe(
        response => {
          this.directionActivities = response;
        }
      )
  }

  onCancel() {
    this.router.navigate(['portfolio']);
  }

  updateState() {
    this.checked = !this.checked;
  }

}