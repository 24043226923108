import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { DirectionActivity } from 'src/app/models/portfolio/directionActivity.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DirectionActivityService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.DirectionActivity}`;

  constructor(private http: HttpClient) { }

  //Get all DirectionActivities
  public getAllDirectionActivities(): Observable<DirectionActivity[]> {
    return this.http.get<DirectionActivity[]>(this.baseUrl + '/GetAllDirectionActivities/')
      .pipe(catchError(this.handleError));
  }

  //Get DirectionActivity by Id
  public getDirectionActivityById(id: number) {
    return this.http.get<DirectionActivity>(this.baseUrl + '/GetDirectionActivityByID/' + id)
      .pipe(
        catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
