import { Component, OnInit } from '@angular/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process, GroupDescriptor, State, aggregateBy } from '@progress/kendo-data-query';
import { LKEducationBudgetTime } from 'src/app/models/education/LKEducationBudgetTime.model';
import { LKEducationPlan } from 'src/app/models/education/LKEducationPlan.model';
import { LKEducationInternsityData } from 'src/app/models/education/LKEducationIntensityData.model';
import { LKEducationProgram } from 'src/app/models/education/LKEducationProgram.model';
import { LKEducationBudgetTimeService } from 'src/app/services/LKEducation/LKEducationBudgetTime/lkeducation-budget-time.service';
import { LKEducationPlanService } from 'src/app/services/LKEducation/LKEducationBUP/lkeducationPlan.service';
import { LKEducationIntensityDataService } from 'src/app/services/LKEducation/LKEducationIntensityData/lkeducation-intensity-data.service';
import { LKEducationProgramService } from 'src/app/services/LKEducation/LKEducationProgram/lkeducation-program.service';
import { EducationStandard } from 'src/app/models/education/EducationStandard.model';
import { DictStudyForm } from 'src/app/models/profile/dictionaries/DictStudyForm.model';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {

  // Table values
  // Направление подготовки
  public educationProgramName?: "";
  // Технология обучения
  public studyTechnologyName? : "";
  // Семестры
  public semestrs?: any = [];

  public myArray = [
    { lkEducationIntensityId: 1, lkEducationIntensityExternalId: '', semestr: 1, classroomLoad: 1, noControl: 1, score: 1, exam: 1, courseWork: 1, scoreGrade: 0 }
  ]

  public studyForms: DictStudyForm[] = [];
  public studyForm: DictStudyForm = {
    studyFormName: '',
    studyFormSName: ''
  }

  public educationStandards: EducationStandard[] = [];
  public educationStandard: EducationStandard = {
    cipher: '',
    name: '',
    shortName: '',
    dictStandardTypeId: '',
    dictEducationLevelId: '',
    dictTrainingLevelId: ''
  }

  public educationPrograms: LKEducationProgram[] = [];
  public educationProgram: LKEducationProgram = {
    lkEducationProgramId: 0,
    lkEducationProgramExternalId: '',
    cipher: '',
    name: '',
    shortname: '',
    educationStandardId: '',
    educationStandard: this.educationStandard,
    dictQualificationId: '',
    departmentId: ''
  }

  public educationPlans: LKEducationPlan[] = [];
  public educationPlan: LKEducationPlan = {
    educationPlanId: 0,
    educationPlanExternalId: "",
    jsonData: "",
    educationProgramId: '',
    educationProgram: this.educationProgram,
    dictStudyFormId: '',
    dictStudyForm: this.studyForm
  }

  public intensityDatas: LKEducationInternsityData[] = [];
  public intensityData: LKEducationInternsityData = {
    lkEducationIntensityId: 0,
    lkEducationIntensityExternalId: "",
    semestr: 0,
    classroomLoad: 0,
    noControl: 0,
    score: 0,
    scoreGrade: 0,
    exam: 0,
    courseWork: 0,
  }

  public budgetTimes: LKEducationBudgetTime[] = [];
  public budgetTime: LKEducationBudgetTime = {
    lkEducationBudgetTimeId: 0,
    lkEducationBudgetTimeExternalId: "",
    totalHours: 0,
    totalZed: 0,
    examHours: 0,
    examZed: 0,
    classHours: 0,
    classZed: 0,
    lectureHours: 0,
    lectureZed: 0,
    practiceHours: 0,
    practiceZed: 0,
    labHours: 0,
    labZed: 0,
    individualHours: 0,
    individualZed: 0
  }

  public gridTest!: [];
  public data!: [];
  public budgetData!: [];
  public bupData = [];

  public aggregates: any[] = [{ field: 'TotalNumberHours', aggregate: 'sum' }, { field: 'TotalLaborIntensity', aggregate: 'sum' }, { field: 'DictDisciplineName', aggregate: 'count' }];
  public total: any = aggregateBy(this.bupData, this.aggregates);

  public state: State = {
    group: [{ field: 'Cipher', aggregates: this.aggregates }]
  };
  public gridData: any = process(this.bupData, this.state);

  public dataStateChange(state: DataStateChangeEvent): void {
    if (state && state.group) {
      state.group.map(group => group.aggregates = this.aggregates);
    }
    this.state = state;
    this.gridData = process(this.bupData, this.state);
  }

  constructor(private educationProgramService: LKEducationProgramService,
    private educationPlanService: LKEducationPlanService,
    private intensityDataService: LKEducationIntensityDataService,
    private budgetTimeService: LKEducationBudgetTimeService) { }

  public getEducationProgram(id: number) {
    this.educationProgramService.getEducationProgramById(id)
      .subscribe(
        response => {
          this.educationProgram = response;
        }
      );
  }

  //Getting BUP by StudentID
  public getEducationProgramsByStudentId(studentId: string) {
    this.educationProgramService.getEducationProgramsByStudentId(studentId)
      .subscribe(
        response => {
          this.educationPrograms = response;
          this.educationProgram = this.educationPrograms[0];
        }
      );
  }

  public getEducationPlans() {
    this.educationPlanService.getAllEducationPlans()
      .subscribe(
        response => {
          this.educationPlans = response;
          //console.log(response);
          var arr = JSON.parse(response[0].jsonData);
          //console.log("JSON", arr);
          // Pass values to HTML
          this.educationProgramName = arr.EducationProgramName;
          this.studyTechnologyName = arr.DictStudyTechnology.Name;
          this.bupData = arr.Disciplines;
          //console.log(this.bupData);
          this.gridData = process(this.bupData, this.state);
          this.total = aggregateBy(this.bupData, this.aggregates);

          // Intensity Data
          this.semestrs = arr.Semesters;
          //console.log("Semests:", this.semestrs)
          buildTable(this.semestrs, this.bupData)
        }
      )
  }

  public getAllIntensityData() {
    this.intensityDataService.getAllEducationIntensityData()
      .subscribe(
        response => {
          this.intensityDatas = response;
          this.myArray = response;
          //buildTable(this.myArray);
        }
      )
  }

  //Getting IntensityData by StudentID
  public getIntensityDataByStudentId(studentId: string) {
    this.intensityDataService.getIntensityDataByStudentId(studentId)
      .subscribe(
        response => {
          this.intensityDatas = response;
          this.myArray = response;
          //buildTable(this.myArray);
        }
      );
  }


  public getBudgetTimeById(int: number) {
    this.budgetTimeService.getBudgetTimeById(int)
      .subscribe(
        response => {
          this.budgetTime = response;
          this.budgetTimes.push(response);
          //console.log("Budget time(1)", this.budgetTime)
        }
      )
  }

  public getAllBudgetTimes() {
    this.budgetTimeService.getAllBudgetTimes()
      .subscribe(
        response => {
          this.budgetTimes = response;
          //console.log("Budget times", this.budgetTimes);
        }
      )
  }

  //Getting BudgetTimes by StudentID
  public getBudgetTimesByStudentId(studentId: string) {
    this.budgetTimeService.getBudgetTimesByStudentId(studentId)
      .subscribe(
        response => {
          this.budgetTimes = response;
          //console.log("Budget times", this.budgetTimes);
        }
      );
  }
  ngOnInit(): void {
    this.getEducationPlans();
    //this.getEducationProgram(1);
    //this.getEducationBups();
    //this.getAllIntensityData();
    //this.getBudgetTimeById(1);


    //this.getEducationProgramsByStudentId("337af85e-0c0e-472c-a7fd-11a20ef0829f");
    //this.getIntensityDataByStudentId("337af85e-0c0e-472c-a7fd-11a20ef0829f")
    //this.getBudgetTimesByStudentId("337af85e-0c0e-472c-a7fd-11a20ef0829f")

    var str = `[
      {
        "educationStandardId": "a7c83417-6763-48d7-b248-9f42c085dd93",
        "directionTraining": "Cipher1 Standard1",
        "educationProgramId": "26fcb745-f056-41fe-aa4d-0fb724cbacb8",
        "educationProgramCipher": "Cipher1",
        "educationProgramName": "Education1",
        "educationLevelId": "763723ec-d6cc-47d2-a1dd-9c66197885cc",
        "educationLevelName": "Test1",
        "educationStandardTypeId": "21b8a18f-1863-4a57-8927-013dd4ebb681",
        "educationStandardTypeName": "Т1",
        "trainingLevelId": "762acc9d-4fb7-49eb-b785-59656e2c0e68",
        "trainingLevelName": "TrainingLevel1",
        "qualificationId": "64da5d5b-b4a5-4820-8d9e-010fcc532c4c",
        "qualificationName": "Qualification1",
        "status": 1,
        "studyFormId": "5520c552-6d2c-4f72-96da-7d7bc44f9647",
        "studyFormName": "StudyForm1",
        "educationPlans": [
          {
            "status": 2,
            "yearAdmission": 1
          }
        ]
      }
    ]`;
    var dataStr = `[
    {
      "oopId": 1,
      "externalId": "26fcb745-f056-41fe-aa4d-0fb724cbacb8",
      "standardId": "a7c83417-6763-48d7-b248-9f42c085dd93",
      "standard": {
        "standardId": 1,
        "standardExternalId": "a7c83417-6763-48d7-b248-9f42c085dd93",
        "standardCipher": "Cipher1",
        "standardName": "Standard1",
        "standardShortName": "E1",
        "standardTypeExternalId": "21b8a18f-1863-4a57-8927-013dd4ebb681",
        "dictStandardType": {
          "standardTypeId": 1,
          "standardTypeExternalId": "21b8a18f-1863-4a57-8927-013dd4ebb681",
          "standardTypeName": "Тест1",
          "standardTypeShortName": "Т1",
          "standardTypePrintName": "PrintedName"
        },
        "educationLevelExternalId": "763723ec-d6cc-47d2-a1dd-9c66197885cc",
        "educationLevel": {
          "educationLevelId": 1,
          "educationLevelExternalId": "763723ec-d6cc-47d2-a1dd-9c66197885cc",
          "educationLevelName": "Test1",
          "educationLevelShortName": "T1"
        },
        "trainingLevelExternalId": "762acc9d-4fb7-49eb-b785-59656e2c0e68",
        "trainingLevel": {
          "trainingLevelId": 1,
          "trainingLevelExternalId": "762acc9d-4fb7-49eb-b785-59656e2c0e68",
          "trainingLevelName": "TrainingLevel1",
          "trainingLevelShortName": "ТL1",
          "trainingLevelProfileName": ""
        },
        "laboriousness": null,
        "link": null,
        "number": null,
        "dateFGOS": null,
        "status": 2,
        "trainingProgram": "Cipher1 - Standard1 (TrainingLevel1, Т1 )"
      },
      "oopCipher": "Cipher1",
      "oopName": "Education1",
      "oopShortName": "E1",
      "kafedraExternalId": "47a83d67-0803-40e6-a600-ee8048968d2b",
      "kafedra": {
        "kafedraId": 1,
        "kafedraExternalId": "47a83d67-0803-40e6-a600-ee8048968d2b",
        "kafedraName": "Department1",
        "kafedraShortName": "D1",
        "facultyId": "1cdc8abe-381a-4622-92f0-07529d430333",
        "faculty": {
          "facultyId": 1,
          "facultyExternalId": "1cdc8abe-381a-4622-92f0-07529d430333",
          "facultyName": "Faculty1",
          "facultyShortName": "F1"
        }
      },
      "facultyExternalId": "1cdc8abe-381a-4622-92f0-07529d430333",
      "faculty": {
        "facultyId": 1,
        "facultyExternalId": "1cdc8abe-381a-4622-92f0-07529d430333",
        "facultyName": "Faculty1",
        "facultyShortName": "F1"
      },
      "qualificationExternalId": "64da5d5b-b4a5-4820-8d9e-010fcc532c4c",
      "qualification": {
        "qualificationId": 1,
        "qualificationExternalId": "64da5d5b-b4a5-4820-8d9e-010fcc532c4c",
        "qualificationName": "Qualification1",
        "qualificationShortName": "Q1",
        "dictEducationLevelId": "763723ec-d6cc-47d2-a1dd-9c66197885cc",
        "dictEducationLevel": {
          "educationLevelId": 1,
          "educationLevelExternalId": "763723ec-d6cc-47d2-a1dd-9c66197885cc",
          "educationLevelName": "Test1",
          "educationLevelShortName": "T1"
        }
      },
      "profilingCourseNumber": null,
      "industrialPartner": null,
      "oopStatus": 1,
      "competenceNumber": 0
    },
    {
      "oopId": 2,
      "externalId": "9088cbc7-3af2-4f1a-8d4d-4396e854d9a9",
      "standardId": "a7c83417-6763-48d7-b248-9f42c085dd93",
      "standard": {
        "standardId": 1,
        "standardExternalId": "a7c83417-6763-48d7-b248-9f42c085dd93",
        "standardCipher": "Cipher1",
        "standardName": "Standard1",
        "standardShortName": "E1",
        "standardTypeExternalId": "21b8a18f-1863-4a57-8927-013dd4ebb681",
        "dictStandardType": {
          "standardTypeId": 1,
          "standardTypeExternalId": "21b8a18f-1863-4a57-8927-013dd4ebb681",
          "standardTypeName": "Тест1",
          "standardTypeShortName": "Т1",
          "standardTypePrintName": "PrintedName"
        },
        "educationLevelExternalId": "763723ec-d6cc-47d2-a1dd-9c66197885cc",
        "educationLevel": {
          "educationLevelId": 1,
          "educationLevelExternalId": "763723ec-d6cc-47d2-a1dd-9c66197885cc",
          "educationLevelName": "Test1",
          "educationLevelShortName": "T1"
        },
        "trainingLevelExternalId": "762acc9d-4fb7-49eb-b785-59656e2c0e68",
        "trainingLevel": {
          "trainingLevelId": 1,
          "trainingLevelExternalId": "762acc9d-4fb7-49eb-b785-59656e2c0e68",
          "trainingLevelName": "TrainingLevel1",
          "trainingLevelShortName": "ТL1",
          "trainingLevelProfileName": ""
        },
        "laboriousness": null,
        "link": null,
        "number": null,
        "dateFGOS": null,
        "status": 2,
        "trainingProgram": "Cipher1 - Standard1 (TrainingLevel1, Т1 )"
      },
      "oopCipher": "Cipher2",
      "oopName": "Education2",
      "oopShortName": "E2",
      "kafedraExternalId": "47a83d67-0803-40e6-a600-ee8048968d2b",
      "kafedra": {
        "kafedraId": 1,
        "kafedraExternalId": "47a83d67-0803-40e6-a600-ee8048968d2b",
        "kafedraName": "Department1",
        "kafedraShortName": "D1",
        "facultyId": "1cdc8abe-381a-4622-92f0-07529d430333",
        "faculty": {
          "facultyId": 1,
          "facultyExternalId": "1cdc8abe-381a-4622-92f0-07529d430333",
          "facultyName": "Faculty1",
          "facultyShortName": "F1"
        }
      },
      "facultyExternalId": "1cdc8abe-381a-4622-92f0-07529d430333",
      "faculty": {
        "facultyId": 1,
        "facultyExternalId": "1cdc8abe-381a-4622-92f0-07529d430333",
        "facultyName": "Faculty1",
        "facultyShortName": "F1"
      },
      "qualificationExternalId": "64da5d5b-b4a5-4820-8d9e-010fcc532c4c",
      "qualification": {
        "qualificationId": 1,
        "qualificationExternalId": "64da5d5b-b4a5-4820-8d9e-010fcc532c4c",
        "qualificationName": "Qualification1",
        "qualificationShortName": "Q1",
        "dictEducationLevelId": "763723ec-d6cc-47d2-a1dd-9c66197885cc",
        "dictEducationLevel": {
          "educationLevelId": 1,
          "educationLevelExternalId": "763723ec-d6cc-47d2-a1dd-9c66197885cc",
          "educationLevelName": "Test1",
          "educationLevelShortName": "T1"
        }
      },
      "profilingCourseNumber": null,
      "industrialPartner": null,
      "oopStatus": 1,
      "competenceNumber": 0
    },
    {
      "oopId": 3,
      "externalId": "af2c7ecf-e9fb-43c3-aca5-9a13e5b1c0ba",
      "standardId": "a7c83417-6763-48d7-b248-9f42c085dd93",
      "standard": {
        "standardId": 1,
        "standardExternalId": "a7c83417-6763-48d7-b248-9f42c085dd93",
        "standardCipher": "Cipher1",
        "standardName": "Standard1",
        "standardShortName": "E1",
        "standardTypeExternalId": "21b8a18f-1863-4a57-8927-013dd4ebb681",
        "dictStandardType": {
          "standardTypeId": 1,
          "standardTypeExternalId": "21b8a18f-1863-4a57-8927-013dd4ebb681",
          "standardTypeName": "Тест1",
          "standardTypeShortName": "Т1",
          "standardTypePrintName": "PrintedName"
        },
        "educationLevelExternalId": "763723ec-d6cc-47d2-a1dd-9c66197885cc",
        "educationLevel": {
          "educationLevelId": 1,
          "educationLevelExternalId": "763723ec-d6cc-47d2-a1dd-9c66197885cc",
          "educationLevelName": "Test1",
          "educationLevelShortName": "T1"
        },
        "trainingLevelExternalId": "762acc9d-4fb7-49eb-b785-59656e2c0e68",
        "trainingLevel": {
          "trainingLevelId": 1,
          "trainingLevelExternalId": "762acc9d-4fb7-49eb-b785-59656e2c0e68",
          "trainingLevelName": "TrainingLevel1",
          "trainingLevelShortName": "ТL1",
          "trainingLevelProfileName": ""
        },
        "laboriousness": null,
        "link": null,
        "number": null,
        "dateFGOS": null,
        "status": 2,
        "trainingProgram": "Cipher1 - Standard1 (TrainingLevel1, Т1 )"
      },
      "oopCipher": "Cipher3",
      "oopName": "Education3",
      "oopShortName": "E3",
      "kafedraExternalId": "47a83d67-0803-40e6-a600-ee8048968d2b",
      "kafedra": {
        "kafedraId": 1,
        "kafedraExternalId": "47a83d67-0803-40e6-a600-ee8048968d2b",
        "kafedraName": "Department1",
        "kafedraShortName": "D1",
        "facultyId": "1cdc8abe-381a-4622-92f0-07529d430333",
        "faculty": {
          "facultyId": 1,
          "facultyExternalId": "1cdc8abe-381a-4622-92f0-07529d430333",
          "facultyName": "Faculty1",
          "facultyShortName": "F1"
        }
      },
      "facultyExternalId": "1cdc8abe-381a-4622-92f0-07529d430333",
      "faculty": {
        "facultyId": 1,
        "facultyExternalId": "1cdc8abe-381a-4622-92f0-07529d430333",
        "facultyName": "Faculty1",
        "facultyShortName": "F1"
      },
      "qualificationExternalId": "64da5d5b-b4a5-4820-8d9e-010fcc532c4c",
      "qualification": {
        "qualificationId": 1,
        "qualificationExternalId": "64da5d5b-b4a5-4820-8d9e-010fcc532c4c",
        "qualificationName": "Qualification1",
        "qualificationShortName": "Q1",
        "dictEducationLevelId": "763723ec-d6cc-47d2-a1dd-9c66197885cc",
        "dictEducationLevel": {
          "educationLevelId": 1,
          "educationLevelExternalId": "763723ec-d6cc-47d2-a1dd-9c66197885cc",
          "educationLevelName": "Test1",
          "educationLevelShortName": "T1"
        }
      },
      "profilingCourseNumber": null,
      "industrialPartner": null,
      "oopStatus": 1,
      "competenceNumber": 0
    }
  ]`
    var budget = `{
    "id": 1,
    "hours": 120,
    "zed": 10
  }`


    //var arr = JSON.parse(str);
    // Pass values to HTML
    //this.educationStandardValue = arr[0].educationStandardId;
    //this.statusValue = arr[0].status;
    //this.directionTrainingValue = arr[0].directionTraining;
    //this.qualificationValue = arr[0].qualificationName;
    //this.oopNameValue = arr[0].educationProgramName;
    //this.gridTest = JSON.parse(dataStr);
    //this.data = JSON.parse(dataStr);
    //this.budgetData = JSON.parse(dataStr);
    //console.log("GRID:", this.gridTest);
    //console.log("Budget data:", this.budgetData);
  }
}

// Построение таблицы "Сводные данные по нагрузке"
function buildTable(intensityDatas: any[], bupData: any[]) {
  var semestrRow = document.getElementById('semestrValues')
  var auditoryRow = document.getElementById('auditoryIntensity')
  var noControlRow = document.getElementById('noControl')
  var scoreRow = document.getElementById('grades')
  var scoreGradeRow = document.getElementById('gradeMark')
  var examRow = document.getElementById('exam')
  var courseWorkRow = document.getElementById('courseWork')
  var rowGroup = document.getElementById('rowGroup')

  //console.log("BUILD TABLE", bupData);

  for (var i = 0; i < intensityDatas.length; i++) {
    // var auditory = `<td class="" role="gridcell" style="border-style: solid; border-color: #ccc;">${intensityDatas[i].classroomLoad}</td>`
    // var noControl = `<td class="" role="gridcell" style="border-style: solid; border-color: #ccc;">${intensityDatas[i].noControl}</td>`
    // var score = `<td class="" role="gridcell" style="border-style: solid; border-color: #ccc;">${intensityDatas[i].score}</td>`
    // var scoreGrade = `<td class="" role="gridcell" style="border-style: solid; border-color: #ccc;">${intensityDatas[i].scoreGrade}</td>`
    // var courseWork = `<td class="" role="gridcell" style="border-style: solid; border-color: #ccc;">${intensityDatas[i].courseWork}</td>`
    // var exam = `<td class="" role="gridcell" style="border-style: solid; border-color: #ccc;">${intensityDatas[i].exam}</td>`
    var semestr = `<th class="k-header" style="border-style: solid; border-color: #ccc;">${intensityDatas[i].Number}</th>`
    // auditoryRow!.innerHTML += auditory
    // noControlRow!.innerHTML += noControl
    // scoreRow!.innerHTML += score
    // scoreGradeRow!.innerHTML += scoreGrade
    // examRow!.innerHTML += exam
    // courseWorkRow!.innerHTML += courseWork
    semestrRow!.innerHTML += semestr
  }

  for (var i=0; i < bupData.length; i++) {
    var group = `<tr role="row"><td role="gridcell">${bupData[i].ControllingActions.DictControlActionName}</td></tr>`
    rowGroup!.innerHTML += group;

  }


}
