<div style="margin: 0 -16px">
    <kendo-tilelayout [resizable]="true" class="paddingFix">
        <kendo-tilelayout-item>
            <kendo-tilelayout-item-body>
                <h2 style="font-family: Montserrat Medium">Образовательная программа</h2>
                <table class="table">
                    <tr>
                        <th>Направление подготовки</th>
                        <td>{{educationProgramName}}</td>
                    </tr>
                    <tr>
                        <th>Профиль</th>
                        <td>21 - Интеллектуальные системы</td>
                    </tr>
                    <tr>
                        <th>Квалификация</th>
                        <td>Магистр</td>
                    </tr>
                    <tr>
                        <th>Форма обучения</th>
                        <td>Очная</td>
                    </tr>
                    <tr>
                        <th>Технология обучения</th>
                        <td>{{studyTechnologyName}}</td>
                    </tr>
                    <tr>
                        <th>Длительность обучения</th>
                        <td>2 года</td>
                    </tr>
                </table>
                <h2 style="font-family: Montserrat Medium">Базовый учебный план</h2>
                <kendo-grid [data]="gridData" (dataStateChange)="dataStateChange($event)" scrollable="none">
                    <kendo-grid-column field="DictCycleName" title="Цикл" [width]="150" [headerStyle]="{'text-align': 'center'}">
                    </kendo-grid-column>
                    <kendo-grid-column field="DictDisciplineName" title="Дисциплина" [headerStyle]="{'text-align': 'center'}">
                    </kendo-grid-column>
                    <kendo-grid-column field="DepartmentName" title="Кафедра" format="n0" [headerStyle]="{'text-align': 'center'}"></kendo-grid-column>
                    <kendo-grid-column field="ControllingActions" title="Семестр"  format="n0" [headerStyle]="{'text-align': 'center'}">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div style="display: inline-flex">
                                <div *ngFor="let item of dataItem.ControllingActions; let isLast=last">
                                    {{item.SemesterNumber}}{{isLast ? '' : ', '}}
                                </div>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column-group title="Трудоемкость" [width]="80" [headerStyle]="{'text-align': 'center'}">
                        <kendo-grid-column field="TotalNumberHours" title="Час." [headerStyle]="{'text-align': 'center'}">+
                        </kendo-grid-column>
                        <kendo-grid-column field="TotalLaborIntensity" title="ЗЕД" [headerStyle]="{'text-align': 'center'}">
                        </kendo-grid-column>
                    </kendo-grid-column-group>
                </kendo-grid>
                <h2 style="font-family: Montserrat Medium">Сводные данные по нагрузке</h2>
                <div class="SemestrWorks">
                    <div class="k-widget k-grid" id="SemestrWorks" data-role="grid">
                        <table role="grid">
                            <thead class="k-grid-header" role="rowgroup">
                                <tr role="row" id="semestrValues" style="text-align: center;">
                                    <th class="k-header" style="border-style: solid; border-color: #ccc; vertical-align: middle; font-weight: 700;
                                        color: rgba(0, 0, 0, 0.54);">Семестр</th>
                                </tr>
                            </thead>
                            <tbody role="rowgroup" id="rowGroup">
                                <!-- <tr role="row" id="auditoryIntensity">
                                    <td class="" role="gridcell">Аудиторная нагрузка</td>
                                </tr>
                                <tr class="k-alt" role="row" id="noControl">
                                    <td class="" role="gridcell">Без контроля</td>
                                </tr>
                                <tr role="row" id="grades">
                                    <td class="" role="gridcell">Зачет</td>
                                </tr>
                                <tr class="k-alt" role="row" id="gradeMark">
                                    <td class="" role="gridcell">Зачет с оценкой</td>
                                </tr>
                                <tr role="row" id="exam">
                                    <td class="" role="gridcell">Экзамен</td>
                                </tr>
                                <tr class="k-alt" role="row" id="courseWork">
                                    <td class="" role="gridcell">Курсовая работа</td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>
                <h2 style="font-family: Montserrat Medium">Сводные данные по бюджету времени</h2>
                <div style="display: flex">
                    <div style="flex: 1">
                        <kendo-grid [data]="budgetTimes" scrollable="none">
                            <kendo-grid-column-group title="Всего" [headerStyle]="{'text-align': 'center'}">
                                <kendo-grid-column field="totalHours" title="Час." [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                            {{ dataItem.totalHours }}
                                          </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="totalZed" title="ЗЕД" [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                            {{ dataItem.totalZed }}
                                          </span>
                                    </ng-template>
                                </kendo-grid-column>
                              </kendo-grid-column-group>
                        </kendo-grid>
                    </div>
                    <div style="flex: 1">
                        <kendo-grid [data]="budgetTimes" scrollable="none">
                            <kendo-grid-column-group title="Экзам." [headerStyle]="{'text-align': 'center'}">
                                <kendo-grid-column field="examHours" title="Час." [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                            {{ dataItem.examHours }}
                                          </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="examZed" title="ЗЕД" [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                            {{ dataItem.examZed }}
                                          </span>
                                    </ng-template>
                                </kendo-grid-column>
                              </kendo-grid-column-group>
                        </kendo-grid>
                    </div>
                    <div style="flex: 1">
                        <kendo-grid [data]="budgetTimes" scrollable="none">
                            <kendo-grid-column-group title="Ауд. работа" [headerStyle]="{'text-align': 'center'}">
                                <kendo-grid-column field="classHours" title="Час." [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                            {{ dataItem.classHours }}
                                          </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="classZed" title="ЗЕД" [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                            {{ dataItem.classZed }}
                                          </span>
                                    </ng-template>
                                </kendo-grid-column>
                              </kendo-grid-column-group>
                        </kendo-grid>
                    </div>
                    <div style="flex: 1">
                        <kendo-grid [data]="budgetTimes" scrollable="none">
                            <kendo-grid-column-group title="Лекции" [headerStyle]="{'text-align': 'center'}">
                                <kendo-grid-column field="lectureHours" title="Час." [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                            {{ dataItem.lectureHours }}
                                          </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="lectureZed" title="ЗЕД" [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                            {{ dataItem.lectureZed }}
                                          </span>
                                    </ng-template>
                                </kendo-grid-column>
                              </kendo-grid-column-group>
                        </kendo-grid>
                    </div>
                    <div style="flex: 1">
                        <kendo-grid [data]="budgetTimes" scrollable="none">
                            <kendo-grid-column-group title="Практ.зан." [headerStyle]="{'text-align': 'center'}">
                                <kendo-grid-column field="practiceHours" title="Час." [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                          {{ dataItem.practiceHours }}
                                        </span>
                                    </ng-template>     
                                </kendo-grid-column>
                                <kendo-grid-column field="practiceZed" title="ЗЕД" [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                            {{ dataItem.practiceZed }}
                                          </span>
                                    </ng-template>
                                </kendo-grid-column>
                              </kendo-grid-column-group>
                        </kendo-grid>
                    </div>
                    <div style="flex: 1">
                        <kendo-grid [data]="budgetTimes" scrollable="none">
                            <kendo-grid-column-group title="Лаб.зан." [headerStyle]="{'text-align': 'center'}">
                                <kendo-grid-column field="labHours" title="Час." [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                            {{ dataItem.labHours }}
                                          </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="labZed" title="ЗЕД" [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                            {{ dataItem.labZed }}
                                          </span>
                                    </ng-template>
                                </kendo-grid-column>
                              </kendo-grid-column-group>
                        </kendo-grid>
                    </div>
                    <div style="flex: 1">
                        <kendo-grid [data]="budgetTimes" scrollable="none">
                            <kendo-grid-column-group title="Сам.раб." [headerStyle]="{'text-align': 'center'}">
                                <kendo-grid-column field="individualZed" title="Час." [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                            {{ dataItem.individualHours }}
                                          </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column field="individualZed" title="ЗЕД" [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                            {{ dataItem.individualZed }}
                                          </span>
                                    </ng-template>
                                </kendo-grid-column>
                              </kendo-grid-column-group>
                        </kendo-grid>
                    </div>
                </div>
            </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
    </kendo-tilelayout>
</div>