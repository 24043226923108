<kendo-appbar position="top" *ngIf="authService.isUserAuthenticated()">
  <kendo-appbar-section>
    <a href="/" class="logo"></a>
    <h1 class="title">ЮНИВУЗ 3.0</h1>
  </kendo-appbar-section>
  <kendo-appbar-spacer></kendo-appbar-spacer>
  <kendo-appbar-section>
    <h1 class="title">Нижегородский государственный технический университет им. Р.Е. Алексеева</h1>
  </kendo-appbar-section>
  <kendo-appbar-spacer></kendo-appbar-spacer>

  <div class="box">
    <kendo-menu [items]="menuItems" class="personMenu">
      <ng-template kendoMenuItemTemplate let-item="item">
        <span class="menuPicture"></span>
<!--        <h3 class="menuTitle"  >{{ item.text }}</h3>-->
        <h3 *ngIf="item.text != 'Выход'; else exittmpl" class="menuTitle"  >{{ item.text }}</h3>
        <ng-template #exittmpl  class="menuTitle"  ><h3 (click)="logOut()" class="menuTitle"  >{{ item.text }}</h3></ng-template>
      </ng-template>
      <ng-template kendoMenuItemContentTemplate let-index="index">
        <span class="menuPicture"></span>
      </ng-template>
    </kendo-menu>
  </div>
</kendo-appbar>
<kendo-drawer-container *ngIf="authService.isUserAuthenticated()">
  <kendo-drawer
    #drawer
    [items]="items"
    [mini]="true"
    mode="push"
    [(expanded)]="expanded"
    (select)="onSelect($event)"
  >
  <ng-template kendoDrawerItemTemplate let-item>
    <a href="{{item.url}}" target="_blank" (click)="menuClick(item)" style="display: block; width: 100%" [class.disabled]="item.disabled == true">
      <div style="margin: 11px 0 11px; align-items: center; display: flex;">
        <span class="k-icon item-icon {{ item.icon }}"></span>
        <span style="margin-left: 27px" *ngIf="expanded">{{ item.text }}</span>
      </div>
    </a>
  </ng-template>
  </kendo-drawer>
  <kendo-drawer-content>
    <kendo-breadcrumb
      (itemClick)="onItemClick($event)"
      [items]="breadcrumbitems"
    ></kendo-breadcrumb>
    <router-outlet></router-outlet>
  </kendo-drawer-content>
</kendo-drawer-container>
<login-page *ngIf="showLoginPage()"></login-page>
<callback *ngIf="showCallbackPage()"></callback>
