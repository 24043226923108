import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import { JwtHelperService } from '@auth0/angular-jwt';
import {TokenStorageService} from "../services/token.service";

@Injectable()
export class AuthGuard implements CanActivate{
  constructor(private jwtHelper: JwtHelperService, private router: Router, private tokenStore: TokenStorageService) {

  }

  canActivate(){
    const token = this.tokenStore.getAuthToken()
    if (token && !this.jwtHelper.isTokenExpired(token)){
      return true
    }
    this.router.navigateByUrl("/login")
    return false;
  }
}
