import {AfterContentInit, AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {filter, map, Observable, Subscription} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {DrawerItem, DrawerItemExpandedFn, DrawerSelectEvent} from '@progress/kendo-angular-layout';
import {BreadCrumbItem} from '@progress/kendo-angular-navigation';
import {breadcrumbItemsMap} from "./models/home/breadcrumb-items.model";
import { Title } from '@angular/platform-browser';
import { menuItems } from './models/menuItems.model';
import { LKStudentService } from './services/LKStudent/lkstudent.service';
import {Renderer2} from '@angular/core';
import { LKStudent } from './models/profile/lkstudent.model';
import { LKStudPerson } from './models/profile/lkStudPerson.model';
import {TokenStorageService} from "./services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {AuthService} from "./services/auth.service";

const is = (fileName: string, ext: string) =>
  new RegExp(`.${ext}\$`).test(fileName);


interface Item {
  text?: string;
  icon?: string;
  path?: string;
  selected?: boolean,
  separator?: boolean,
  id?: string,
  parentId?: string,
  url?: string
  disabled?: boolean,
  return?: boolean
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: []
})

export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'Личный кабинет стдуента';

  public selected = "Inbox";
  public expanded = true;
  private routesData: Subscription = new Subscription;
  public items: Array<Item> = [
    {icon: "k-i-menu"},
    {text: "Профиль", icon: "k-i-user", selected: true, path: "/", id: "home"},
    // {separator: true},
    {text: "Портфолио", icon: "k-i-inherited", path:"/portfolio", id: "portfolio"},
    {text: "Образование", icon: "k-i-chart-rose", path: "/education", id: "education"},
    {text: "Портал дистанционного обучения", icon:"k-i-exe", url:"https://edu.nntu.ru/", return: true},
    {text: "ЭБС", icon:"k-i-dictionary-add", url: "https://library.nntu.ru/MegaPro/Web/Search/Simple", return: true},
    {text: "Официальный сайт НГТУ", icon:"k-i-hyperlink-open", url: "https://www.nntu.ru/", return: true},
    {text: "Новости и объявления", icon:"k-i-parameter-date-time", path: "/WIP", disabled: true, id: "wip"},
    {text: "Расписание занятий", icon: "k-i-calendar-date", path: "/WIP", disabled: true, id: "wip"},
    {text: "Расписание экзаменов", icon: "k-i-calendar", path: "/WIP", disabled: true, id: "wip"},
    {text: "Успеваемость", icon:"k-i-graph", path: "/WIP", id: "wip", disabled: true},
    {text: "Стипендии", icon:"k-i-calculator", path: "/WIP", disabled: true, id: "wip"},
    {text: "Социальные документы", icon:"k-i-files-error", disabled: true, path: "/WIP", id: "wip"}
  ];

  public menuItems: any[] = menuItems;

  public iconClass({ text, items }: any): any {
    return {
      "k-i-file-pdf": is(text, "pdf"),
      "k-i-folder": items !== undefined,
      "k-i-html": is(text, "html"),
      "k-i-image": is(text, "jpg|png"),
      "k-icon": true,
    };
  }

  public updateStudentName() {
    const test = this.menuItems.find(item => item.text);
    menuItems[0].text;
  }

  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  // Student
  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "",
    studPersonId: "",
    studPerson: this.studPerson
  }

  //Getting student info
  public getStudent(id: number) {
    this.studentService.getStudentById(id)
      .subscribe(
        response => {
          menuItems[0].text = response.studPerson.lastName + " " + response.studPerson.firstName + " " +  response.studPerson.middleName;
          //console.log(response.fullName)
          this.studentModel = response;
        }
      );
  }

  //Getting student by login
  public getStudentByLogin() {
    this.studentService.getStudentByLogin( )
      .subscribe(
        response => {
          menuItems[0].text = response[0].studPerson.lastName + " " + response[0].studPerson.firstName + " " + response[0].studPerson.middleName;
          this.studentModel.studPerson.firstName = response[0].studPerson.firstName + " " + response[0].studPerson.lastName + " " + response[0].studPerson.middleName;
        }
      );
  }


  public onSelect(ev: DrawerSelectEvent): void {
    //console.log(this.expanded);
    if (ev.item.icon == "k-i-menu") {
      this.expanded = !this.expanded;

    }
    else if (ev.item.path != undefined)
      this.router.navigate([ev.item.path]);

  }


  public breadcrumbitems: BreadCrumbItem[] = [
    {
      text: "Главная",
      title: "home",

    },
    {
      text: "Моя страница",
      title: "studentPersonalPage",
    }
  ];

  public expandedIndices = [2];
  public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };

  constructor(private router: Router,
    private titleService: Title,
    private studentService: LKStudentService,
    private el: ElementRef,
    private renderer: Renderer2,
              private jwtHelper: JwtHelperService,
              private tokenStore: TokenStorageService,
              public authService : AuthService
  ) {
    this.initRoutes();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.getWidth(), 1000);
  }

  ngOnInit(): void {
    if(1 || this.authService.isUserAuthenticated())
      this.getStudentByLogin()

    // Add page titles to components
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route: ActivatedRoute = this.router.routerState.root;
        let routeTitle = '';
        while (route!.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot.data['title']) {
          routeTitle = route!.snapshot.data['title'];
        }
        return routeTitle;
      })
    )
    .subscribe((title: string) => {
      if (title) {
        this.titleService.setTitle(`${title} – личный кабинет студента`);
      }
    });
  }

  public getWidth()
  {
    const box = document.querySelector('.box') as HTMLElement;
    //console.log("Test:", box?.clientWidth);
    const test = document.querySelector('.k-menu-popup') as HTMLElement | undefined;
    //test!.style.width = "" + box?.clientWidth + "px";
    //console.log("Popup-width:", test?.clientWidth);
  }

    public baseSportsIconUrl =
    "https://demos.telerik.com/kendo-ui/content/shared/icons/sports/";
  public baseIconUrl =
    "https://demos.telerik.com/kendo-ui/content/shared/icons/16/";

  public sportsIconUrl(imageName: string): string {
    return this.baseSportsIconUrl + imageName + ".png";
  }

  public iconUrl(imageName: string): string {
    return this.baseIconUrl + imageName + ".png";
  }

  public ngOnDestroy(): void {
    this.routesData.unsubscribe();
  }

  public menuClick(value: any) {
    switch (value.return)
    {
      case true:
        return true;
      case false:
        return false;
      default:
        return false;
    }
  }

  public onItemClick(item: BreadCrumbItem): void {
    const selectedItemIndex = this.breadcrumbitems.findIndex((i) => i.title === item.title);
    const url = this.breadcrumbitems
      .slice(0, selectedItemIndex + 1)
      .map((i) => `${i.title?.toLowerCase()}` );
    this.router.navigate(url);
  }

  private initRoutes(): void {
    this.routesData = this.router.events.subscribe(() => {
      // Exclude query parameters from URL
      const route = this.router.url;
      this.breadcrumbitems = route
        .substring(0, route.indexOf('?') !== -1 ? route.indexOf('?') : route.length)
        .split('/')
        .filter((segment)=> breadcrumbItemsMap.has(segment))
        .map((segment) => {
          return {
            text: breadcrumbItemsMap.get(segment),
            title: segment
          };
        });

      this.breadcrumbitems = [
        // {
        //   text: 'Главная',
        //   title: ''
        // },
        ...this.breadcrumbitems
      ];
    });
  }


  showLoginPage(){
    let route = window.location.pathname//this.router.url
    return /*!this.authService.isUserAuthenticated() &&*/ route != "/callback" // route.substring(0, route.indexOf('?') !== -1 ? route.indexOf('?') : route.length) != "/callback"
  }

  showCallbackPage(){
    let route = window.location.pathname//this.router.url
    return /*!this.authService.isUserAuthenticated() &&*/ route == "/callback"// route.substring(0, route.indexOf('?') !== -1 ? route.indexOf('?') : route.length) == "/callback"
  }


  public logOut = () => {
    window.location.reload();
    this.tokenStore.deleteToken()
  }
}
