import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EducationComponent } from './components/education/education.component';
import { ExamListComponent } from './components/exam-list/exam-list.component';
import { HomeComponent } from './components/home/home.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { PortfolioAddFormComponent } from './components/portfolio/portfolio-add-form/portfolio-add-form.component';
import { ProgressComponent } from './components/progress/progress.component';
import { TimeTableComponent } from './components/timetable/timetable.component';
import { PortfolioEditFormComponent } from './components/portfolio/portfolio-edit-form/portfolio-edit-form.component';
import {CallBackComponent} from "./components/auth/callback.component";
import {LoginpageComponent} from "./components/auth/loginpage.component";
import {AuthGuard} from "./guards/authguard";

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', data: {title: 'Моя страница'} },
  { path: 'login', component: LoginpageComponent},
  { path: 'callback', component: CallBackComponent},
  { path: 'home', component: HomeComponent, data: {title: 'Моя страница'}, canActivate: [AuthGuard] },
  { path: 'ExamList', component: ExamListComponent, data: {title: 'Расписание экзаменов'}, canActivate: [AuthGuard] },
  { path: 'TimeTable', component: TimeTableComponent, data: {title: 'Расписание занятий'}, canActivate: [AuthGuard] },
  { path: 'StudyProgress', component: ProgressComponent, data: {title: 'Успеваемость'}, canActivate: [AuthGuard] },
  { path: 'portfolio/addPortfolio', component: PortfolioAddFormComponent, canActivate: [AuthGuard]},
  { path: 'portfolio/editPortfolio/:id', component: PortfolioEditFormComponent, canActivate: [AuthGuard]},
  { path: 'education', component: EducationComponent, data: {title: 'Образование'} , canActivate: [AuthGuard]},
  { path: 'portfolio', component: PortfolioComponent, data: {title: 'Портфолио'} , canActivate: [AuthGuard]},
  { path: 'WIP', component: ExamListComponent, data: {title: 'В разработке'}, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
