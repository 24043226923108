import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CellClickEvent, GridComponent, GridDataResult, RowClassArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { saveAs } from '@progress/kendo-file-saver';
import { Observable } from 'rxjs';
import { DirectionActivity } from 'src/app/models/portfolio/directionActivity.model';
import { LKPortfolio } from 'src/app/models/portfolio/portfolio.model';
import { ScanFile } from 'src/app/models/portfolio/scanFile.model';
import { LKStudent } from 'src/app/models/profile/lkstudent.model';
import { LKStudPerson } from 'src/app/models/profile/lkStudPerson.model';
import { DirectionActivityService } from 'src/app/services/LKPortfolio/direction-activity.service';
import { LKPortfolioService } from 'src/app/services/LKPortfolio/lkportfolio.service';
import { LKStudentService } from 'src/app/services/LKStudent/lkstudent.service';
import { NotificationsService } from 'src/app/services/Notifications/notifications.service';

@Component({
  selector: 'portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  private selectedPortfolio: any = []

  public filesTest?: any = [];
  public opened = false;

  public editDataItem!: LKPortfolio | undefined;
  @Input() public set model(lkPortfolio: LKPortfolio) {
    this.editForm.reset(lkPortfolio);
  }

  public selectableSettings!: SelectableSettings;

  public portfolioNoData = false;
  public view: Observable<GridDataResult> | undefined;

  @Output() save: EventEmitter<LKPortfolio> = new EventEmitter();

  @ViewChild(GridComponent) private grid!: GridComponent;

  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  // StudPerson
  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  // Student
  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "",
    studPersonId: "",
    studPerson: this.studPerson
  }

  public scanFiles!: ScanFile[];
  public scanFile: ScanFile = {
    fileName: ""
  }


  public portfolios: LKPortfolio[] = [];
  public portfolio: LKPortfolio = {
    portfolioId: 0,
    studentId: "",
    portfolioExternalId: "",
    directionActivityId: "",
    status: "",
    name: "",
    condition: "",
    subCondition: "",
    marksWithCondition: "",
    eventDate: new Date(),
    expirationDate: new Date(),
    notes: "",
    review: false,
    scanFile: this.scanFiles
  }

  public directionActivities!: DirectionActivity[];
  public directionActivity: DirectionActivity = {
    directionActivityId: 0,
    directionActivityExternalId: "",
    name: "",
    portfolio: this.portfolios
  }

  public editForm: FormGroup = new FormGroup({
    portfolioId: new FormControl(),
    studentId: new FormControl(),
    portfolioExternalId: new FormControl(),
    directionActivityExternalId: new FormControl(),
    status: new FormControl(),
    name: new FormControl(),
    condition: new FormControl(),
    subCondition: new FormControl(),
    marksWithCondition: new FormControl(),
    eventDate: new FormControl(),
    expirationDate: new FormControl(),
    notes: new FormControl(),
    review: new FormControl(),
    scanFile: new FormGroup({
      file: new FormControl(),
      fileName: new FormControl()
    })
  });

  constructor(private router: Router,
    private portfolioService: LKPortfolioService, 
    private directionActivitiesService: DirectionActivityService,
    private notificationService: NotificationsService,
    private studentService: LKStudentService) {

  }

  public onSave(e: PointerEvent): void {
    e.preventDefault();
    this.save.emit(this.editForm.value);
  }

  ngOnInit(): void {
    this.getStudentByLogin("123456")
    this.getDirectionActivities();
  }

  //Getting student by login
  public getStudentByLogin(login: string) {
    this.studentService.getStudentByLogin()
      .subscribe(
        response => {
          this.students = response;
          this.studentModel = response[0];
          //console.log(this.studentModel);
          this.getPortfoliosByStudentId(`${this.studentModel.externalId}`);
        }
      );
  }

  public portfolioAddForm(){
    this.router.navigate(['portfolio/addPortfolio']);
  }

  public downloadFile(values: any)
  {
    this.portfolioService.downloadFile(`${values.studentId}/${values.directionActivityId}/${values.portfolioExternalId}/${values.scanFile[0].fileName}`).subscribe(
      response => {
        let blob:any = new Blob([response], { type: `application/pdf; charset=utf-8` });
        saveAs(blob, values.scanFile[0].fileName);
      }
    );
  }

  public portfolioEditForm(cell: CellClickEvent): void {
    //this.router.navigate(['portfolio/editPortfolio']);
    //console.log("Cell clicked", cell.dataItem);
    //console.log(cell.dataItem.portfolioId)
    this.router.navigate(['portfolio/editPortfolio/' + cell.dataItem.portfolioId])
  }

  //Getting all entries from Portfolio
  public getPortfolios() {
    this.portfolioService.getAllPortfolios()
      .subscribe(
        response => {
          this.portfolios = response;
        }
      )
  }

  //Getting Portfolios by studentId
  public getPortfoliosByStudentId(studentId: string) {
    this.portfolioService.getPortfoliosByStudentId(studentId)
      .subscribe(
        response => {
          this.portfolios = response;
          /*for (let i = 0; i < this.portfolios.length; i++) {
            this.scanFiles.push(this.portfolios[i].scanFile)
          }
          console.log("Scan files", this.scanFiles)*/
        }
      );
  }

  public getDirectionActivities() {
    this.directionActivitiesService.getAllDirectionActivities()
    .subscribe(
      response => {
        this.directionActivities = response;
      }
    )
  }

  public deletePortfolio() {
    this.opened = false;
    this.portfolioService.deleteLKPortfolio(this.selectedPortfolio.portfolioId)
    .subscribe(
      response => {
        this.notificationService.showSuccess("Достижение успешно удалено!");
        this.update();
      },
      error => {
        this.notificationService.showError("Не удалось удалить достижение");
        this.update();
      }
    )

  }

  public noDataCheck(array: LKPortfolio[]): boolean {
    this.portfolioNoData = false;
      if (array.length == 0) {
        this.portfolioNoData = true;
      }
      return this.portfolioNoData
  }

  //Change cell color of status depending on value
  public rowClassCallback = (context: RowClassArgs) => {
    switch (context.dataItem.portfolio.status) {
      case 1:
        context.dataItem.portfolio.status = "Добавлено"
        return {};
      case 2:
        return { red: true };
      case "Добавлено":
        return { blue: true };
      default:
        return {};
    }
  }

  public open(values: string) {
    this.opened = true;
    this.selectedPortfolio = values;
    //console.log("Selected portfolio", this.selectedPortfolio.portfolioId)
  }

  public update() {
    this.getPortfoliosByStudentId("337af85e-0c0e-472c-a7fd-11a20ef0829f");
    this.getDirectionActivities();
    this.view;
  }

  public close() {
    this.opened = false;
  }

  public onStateChange(state: State): void {
    this.getPortfoliosByStudentId("337af85e-0c0e-472c-a7fd-11a20ef0829f")
    this.getDirectionActivities();
    this.gridState = state;
    this.view;
  }

}