import { JsonPipe } from '@angular/common';
import { VerticalAlign } from "@progress/kendo-angular-layout";
import { Component, Input, OnInit } from '@angular/core';
import { LKStudent } from 'src/app/models/profile/lkstudent.model';
import { LKStudentService } from 'src/app/services/LKStudent/lkstudent.service';
import {AlignSettings} from "@progress/kendo-angular-layout/layouts/models";
import { LKStudPerson } from 'src/app/models/profile/lkStudPerson.model';
import { LKEmail } from 'src/app/models/profile/lkEmail.model';
import { LKLanguages } from 'src/app/models/profile/lkLanguages.model';
import { LKOrdersStudyPeriod } from 'src/app/models/profile/lkOrdersStudyPeriod.model';
import { LKPhone } from 'src/app/models/profile/lkPhone.model';
import { LKStudEduGroup } from 'src/app/models/profile/lkStudEduGroup.model';
import { LKEduGroup } from 'src/app/models/profile/lkEduGroup.model';
import { LKDictOrganization } from 'src/app/models/profile/dictionaries/lkDictOrganization.model';
import { LKDictStudentAcademicState } from 'src/app/models/profile/dictionaries/lkDictStudentAcademicState.model';
import { LKDictStudentBudgetSubCategory } from 'src/app/models/profile/dictionaries/lkDictStudentBudgetSubCategory.model';
import { LKDictStudentBudget } from 'src/app/models/profile/dictionaries/lkDictStudentBudget.model';
import { LKStudentAcademicState } from 'src/app/models/profile/lkStudentAcademicState.model';
import { LKStudentBudgetState } from 'src/app/models/profile/lkStudentBudgetState.model';
import { LKDictOrganizationService } from 'src/app/services/LKStudent/dictionaries/lkdict-organization.service';
import { LKDictStudentAcademicStateService } from 'src/app/services/LKStudent/dictionaries/lkdict-student-academic-state.service';
import { LKDictStudentBudgetService } from 'src/app/services/LKStudent/dictionaries/lkdict-student-budget.service';
import { LKDictStudentBudgetSubCategoryService } from 'src/app/services/LKStudent/dictionaries/lkdict-student-budget-sub-category.service';
import { LKEduGroupService } from 'src/app/services/LKStudent/lkedu-group.service';
import { LKEmailService } from 'src/app/services/LKStudent/lkemail.service';
import { LKLanguagesService } from 'src/app/services/LKStudent/lklanguages.service';
import { LKOrdersStudyPeriodService } from 'src/app/services/LKStudent/lkorders-study-period.service';
import { LKPhoneService } from 'src/app/services/LKStudent/lkphone.service';
import { LKStudEduGroupService } from 'src/app/services/LKStudent/lkstud-edu-group.service';
import { LKStudentAcademicStateService } from 'src/app/services/LKStudent/lkstudent-academic-state.service';
import { LKStudentBudgetStateService } from 'src/app/services/LKStudent/lkstudent-budget-state.service';
import { LKStudPersonService } from 'src/app/services/LKStudent/lkstud-person.service';
import { LKDictLanguages } from 'src/app/models/profile/dictionaries/LkDictLanguages.model';
import { LKDictLanguageLevels } from 'src/app/models/profile/dictionaries/lkDictLanguageLevels.model';
import { LKEducationPlan } from 'src/app/models/education/LKEducationPlan.model';
import { LKEducationPlanService } from 'src/app/services/LKEducation/LKEducationBUP/lkeducationPlan.service';
import { Department } from 'src/app/models/profile/external/department.model';
import { Faculty } from 'src/app/models/profile/external/faculty.model';
import { LKEducationProgram } from 'src/app/models/education/LKEducationProgram.model';
import { EducationStandard } from 'src/app/models/education/EducationStandard.model';
import { DictStudyForm } from 'src/app/models/profile/dictionaries/DictStudyForm.model';
@Component({
  selector: 'client-apps-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  ///////// Переменные профиля: /////////////


  // ФИО
  public fullName? = "-"
  // Номер студента
  public studentNumberValue? = "-"
  // Источник финансирования
  public budgetStateValue? = "-"
  // Форма обучения
  public studyFormValue? = "-"
  // Факультет (институт)
  public facultyValue? = "-"
  // Курс
  public courseValue? = "-"
  // Группа
  public groupNameValue? = "-"
  // Направление обучения
  public studyDirection? = "-"
  // Направленность (профиль)
  public profile? = "-";
  // Кафедра
  public departmentValue? = "-"
  // Академический статус
  public academicStatusValue? = "-"
  // Заказчик целевого обучения
  public organizationValue? = "-"
  // Изучаемые языки
  public languagesValue? = "-"
  // Средний балл
  public markAverageValue? = "-"
  // Дата начала обучения
  public dateStartValue = new Date()
  // Год выпуска
  public dateEndValue = new Date()

  // Email
  public emailValue = "-"
  // Телефон
  public phoneValue = "-"

  ///////////////////////////////////////////

  public shortInformation = true;
  public align: AlignSettings = {vertical: "top"}
  public emailText = "";
  public currentDate = new Date();
  public dayName = "";

  public educationPlanData: any;

  datePipe: any;
  constructor(
    private studentService: LKStudentService,
    private dictOrganizationService: LKDictOrganizationService,
    private dictStudentAcademicStateService: LKDictStudentAcademicStateService,
    private dictStudentBudgetService: LKDictStudentBudgetService,
    private dictStudentBudgetSubCategoryService: LKDictStudentBudgetSubCategoryService,
    private eduGroupService: LKEduGroupService,
    private emailService: LKEmailService,
    private languagesService: LKLanguagesService,
    private ordersStudyPeriodService: LKOrdersStudyPeriodService,
    private phoneService: LKPhoneService,
    private studEduGroupService: LKStudEduGroupService,
    private studentAcademicStateService: LKStudentAcademicStateService,
    private studentBudgetStateService: LKStudentBudgetStateService,
    private studPersonService: LKStudPersonService,
    private educationPlanService: LKEducationPlanService) {
      // Получить текущее название дня недели
      let daysArray = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
      let day = new Date().getDay();
      this.dayName = daysArray[day]
    }

  public gridData = [
    { channel: "Работников всего", conversion: 8232, users: 1539 },
    { channel: "ППС", conversion: 6574, users: 935 },
    { channel: "АУП", conversion: 4932, users: 150 },
    { channel: "Прочие", conversion: 1172, users: 454 },
  ];
  public eventsData = [
    { id: 1, title: "LIST FOR THE ALIMENTARY CONTROL ON PREPARATIONS", author: "Курникова Анна Александровна", date: "20.03.2022", annotation: "List for the alimentary control on preparations" },
    { id: 2, title: "LIST FOR THE ALIMENTARY CONTROL ON PREPARATIONS", author: "Курникова Анна Александровна", date: "20.03.2022", annotation: "List for the alimentary control on preparations" },
    { id: 3, title: "LIST FOR THE ALIMENTARY CONTROL ON PREPARATIONS", author: "Курникова Анна Александровна", date: "20.03.2022", annotation: "List for the alimentary control on preparations" },
    { id: 4, title: "LIST FOR THE ALIMENTARY CONTROL ON PREPARATIONS", author: "Курникова Анна Александровна", date: "20.03.2022", annotation: "List for the alimentary control on preparations" },
  ];

  public examsData = [
    { id: 1, subject: "Психологическое сопровождение пациентов в общей медицинской практике", author: "Курникова Анна Александровна",
    date: "20.03.2022", location: "List for the alimentary control on preparations" },
    { id: 2, subject: "Психология, психопатология и психотерапия зависимостей", author: "Курникова Анна Александровна",
    date: "20.03.2022", location: "List for the alimentary control on preparations" },
    { id: 3, subject: "Типология личностных расстройств", author: "Курникова Анна Александровна",
    date: "20.03.2022", location: "List for the alimentary control on preparations" },
  ];

  public studentData = [{id: 1, fullName: "Васькин Александр Анатольевич", studentCardNumber: "20002/м", profile:"(01) Клиническая психология",
  faculty: "N/A", speciality:"N/A", courseNumber: 4, group: "КП-4", academicStatus: "Учится", studentCode: "N/A", email:"vaskin-family@rambler.ru" }];


  public studyForms: DictStudyForm[] = [];
  public studyForm: DictStudyForm = {
    studyFormName: '',
    studyFormSName: ''
  }

  public educationStandards: EducationStandard[] = [];
  public educationStandard: EducationStandard = {
    cipher: '',
    name: '',
    shortName: '',
    dictStandardTypeId: '',
    dictEducationLevelId: '',
    dictTrainingLevelId: ''
  }

  public educationPrograms: LKEducationProgram[] = [];
  public educationProgram: LKEducationProgram = {
    lkEducationProgramId: 0,
    lkEducationProgramExternalId: '',
    cipher: '',
    name: '',
    shortname: '',
    educationStandardId: '',
    educationStandard: this.educationStandard,
    dictQualificationId: '',
    departmentId: ''
  }

  public educationPlans: LKEducationPlan[] = [];
  public educationPlan: LKEducationPlan = {
    educationPlanId: 0,
    educationPlanExternalId: "",
    jsonData: "",
    educationProgramId: '',
    educationProgram: this.educationProgram,
    dictStudyFormId: '',
    dictStudyForm: this.studyForm
  }


  // StudPerson
  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  // Student
  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "-",
    studPersonId: "",
    studPerson: this.studPerson
  }

  public faculties: Faculty[] = [];
  public faculty: Faculty = {
    name: '-',
    shortName: '-'
  }

  public departments: Department[] = [{name: '-',
  shortName: '-',}];
  public department: Department = {
    name: '-',
    shortName: '-',
    faculty: this.faculty
  }

  public eduGroups: LKEduGroup[] = [];
  public eduGroup?: LKEduGroup = {
    courseNum: 0,
    groupName: "-",
    department: this.department
  }

  public dictOrganizations: LKDictOrganization[] = [];
  public dictOrganization: LKDictOrganization = {
    organizationName: '-'
  }

  public dictStudentAcademicStates: LKDictStudentAcademicState[] = [];
  public dictStudentAcademicState: LKDictStudentAcademicState = {
    academicStateName: '-'
  }

  public dictStudentBudgets: LKDictStudentBudget[] = [];
  public dictStudentBudget?: LKDictStudentBudget = {
    studentBudgetName: '-',
    studentBudgetSName: '-',
    isBudget: false
  }

  public dictStudentBudgetSubCategories: LKDictStudentBudgetSubCategory[] = [];
  public dictStudentBudgetSubCategory: LKDictStudentBudgetSubCategory = {
    dictStudentBudgetId: '',
    dictStudentBudget: this.dictStudentBudget,
    budgetSubCategoryName: '-'
  }

  public studentAcademicStates: LKStudentAcademicState[] = [];
  public studentAcademicState: LKStudentAcademicState = {
    studentId: '',
    dictStudentAcademicStateId: '',
    dictStudentAcademicState: this.dictStudentAcademicState
  }

  public studentBudgetStates: LKStudentBudgetState[] = [];
  public studentBudgetState: LKStudentBudgetState = {
    studentId: '',
    dictStudentBudgetId: '',
    dictStudentBudget: this.dictStudentBudget,
    dictOrganization: this.dictOrganization,
    dictStudentBudgetSubCategory: this.dictStudentBudgetSubCategory
  }

  public studEduGroups: LKStudEduGroup[] = [];
  public studEduGroup?: LKStudEduGroup = {
    studentId: '',
    eduGroupId: '',
    eduGroup: this.eduGroup
  }

  public phones: LKPhone[] = [];
  public phone?: LKPhone = {
    studPersonId: '',
    phoneNumber: '-'
  }

  public ordersStudyPeriods: LKOrdersStudyPeriod[] = [];
  public orderStudyPeriod: LKOrdersStudyPeriod = {
    studentId: '',
    dateStart: new Date(),
    dateFinish: new Date()
  }

  public dictLanguageLevels: LKDictLanguageLevels[] = [];
  public dictLanguageLevel?: LKDictLanguageLevels = {
    levelName: '-'
  }

  public dictLanguages: LKDictLanguages[] = []
  public dictLanguage?: LKDictLanguages = {
    languageName: '-'
  }

  public languages: LKLanguages[] = [];
  public language?: LKLanguages = {
    studPersonId: '',
    dictLanguageId: '',
    dictLanguage: this.dictLanguage,
    dictLanguageLevelId: '',
    dictLanguageLevel: this.dictLanguageLevel
  }

  public emails: LKEmail[] = [];
  public email?: LKEmail = {
    studPersonId: '',
    email: '-'
  }

  public test!: "";

  //Getting student info
  public getStudent(id: number) {
    this.studentService.getStudentById(id)
      .subscribe(
        response => {
          this.studentModel = response;
        }
      );
  }

  //Getting student by login
  public getStudentByLogin() {
    this.studentService.getStudentByLogin()
      .subscribe(
        response => {
          this.students = response;
          this.studentModel = response[0];
          this.studentNumberValue = this.studentModel.studentNumber;
          this.fullName = this.studentModel.studPerson.lastName + " " + this.studentModel.studPerson.firstName + " " +  this.studentModel.studPerson.middleName;
          //console.log(this.studentModel);
          this.getEmail(`${this.studentModel.studPersonId}`);
          this.getPhones(`${this.studentModel.studPersonId}`);
          this.getLanguages(`${this.studentModel.studPersonId}`);
          this.getStudentAcademicStatus(`${this.studentModel.externalId}`);
          this.getOrdersStudyPeriod(`${this.studentModel.externalId}`);
          this.getStudEduGroup(`${this.studentModel.externalId}`);
          this.getStudentBudgetState(`${this.studentModel.externalId}`);
          this.getEducationPlanByStudentId(`${this.studentModel.externalId}`);
          this.getEducationProgramByStudentId(`${this.studentModel.externalId}`);
          this.getStudyFormByStudentId(`${this.studentModel.externalId}`);
        }
      );
  }

  public getEmail(studPersonId: string) {
    this.emailService.getEmails(studPersonId)
    .subscribe(
      response => {
        this.email = response;
        this.emailValue = this.email?.email;
      }
    );
  }

  public getStudEduGroup(studentId: string) {
    this.studEduGroupService.getStudEduGroup(studentId)
    .subscribe(
      response => {
        this.studEduGroup = response;
        this.facultyValue = this.studEduGroup?.eduGroup?.department?.faculty?.name;
        this.courseValue = this.studEduGroup?.eduGroup?.courseNum.toString();
        this.groupNameValue = this.studEduGroup?.eduGroup?.groupName;
        this.departmentValue = this.studEduGroup?.eduGroup?.department?.name;
        //var arr = JSON.parse(this.studEduGroup.eduGroup);
        //this.educationPlanData = arr;
        //console.log(this.educationPlanData)
      }
    );
  }

  public getPhones(studPersonId: string) {
    this.phoneService.getPhones(studPersonId)
    .subscribe(
      response => {
        this.phone = response;
        this.phoneValue = this.phone.phoneNumber;
      }
    );
  }

  public getOrdersStudyPeriod(studentId: string) {
    this.ordersStudyPeriodService.getOrdersStudyPeriods(studentId)
    .subscribe(
      response => {
        this.orderStudyPeriod = response;
        //console.log("Orders:", this.orderStudyPeriod);
      }
    );
  }

  public getLanguages(studPersonId: string) {
    this.languagesService.getLanguages(studPersonId)
    .subscribe(
      response => {
        this.languages = response;
        this.languagesValue = "";
        for(var item of this.languages)
        {
          if(item?.dictLanguage?.languageName != null)
          {
            this.languagesValue += item?.dictLanguage?.languageName + ", ";
          }
        }
        this.languagesValue = this.languagesValue.slice(0, -2);
        //console.log(this.languages);
      }
    );
  }

  public getStudentAcademicStatus(studentId: string) {
    this.studentAcademicStateService.getStudentAcademicStates(studentId)
    .subscribe(
      response => {
        this.studentAcademicState = response;
        if(this.studentAcademicState?.dictStudentAcademicState?.academicStateName != null
          && this.studentBudgetState?.dictOrganization?.organizationName != null)
        {
          this.academicStatusValue = this.studentAcademicState?.dictStudentAcademicState?.academicStateName;
          this.organizationValue = this.studentBudgetState?.dictOrganization?.organizationName;
        }
      }
    );
  }

  public getStudentBudgetState(studentId: string) {
    this.studentBudgetStateService.getStudentBudgetStates(studentId)
    .subscribe(
      response => {
        this.studentBudgetState = response;
        if(this.studentBudgetState.dictStudentBudget?.isBudget == true)
        {
          this.budgetStateValue = /*"Бюджет, " +*/ this.studentBudgetState.dictStudentBudget?.studentBudgetName;//.toLowerCase();
        }
        else {
          this.budgetStateValue = /*"Договор, " +*/ this.studentBudgetState.dictStudentBudget?.studentBudgetName;//.toLowerCase();
        }
        //console.log(this.studentBudgetState)
        this.organizationValue = this.studentBudgetState.dictOrganization?.organizationName;
        //console.log(this.studentBudgetState);
      }
    );
  }

  public getEducationPlanByStudentId(studentId: string) {
    this.studEduGroupService.getEducationPlanByStudentId(studentId)
      .subscribe(
        response => {
          this.educationPlan = response;
        }
      )
  }

  public getEducationProgramByStudentId(studentId: string) {
    this.studEduGroupService.getEducationProgramByStudentId(studentId)
      .subscribe(
        response => {
          this.educationProgram = response;
          //console.log("Education program", response);
          this.studyDirection = this.educationProgram.educationStandard.cipher + " - " + this.educationProgram.educationStandard.name;
          this.profile = "(" + this.educationProgram.cipher + ") " + this.educationProgram.name;
        }
      )
  }

  public getStudyFormByStudentId(studentId: string) {
    this.studEduGroupService.getStudyFormByStudentId(studentId)
      .subscribe(
        response => {
          this.studyForm = response;
          //console.log("StudyForm", this.studyForm);
          this.studyFormValue = this.studyForm.studyFormName;
        }
      )
  }

  public showStudentInformation() {
    this.shortInformation = !this.shortInformation;
  }



  ngOnInit(): void {
    this.getStudentByLogin();
  }
}
