import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private notificationService: NotificationService) { }

  public showSuccess(message: string): void {
    this.notificationService.show({
      content: message ?? '',
      hideAfter: 1500,
      width: 500,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "fade", duration: 300 },
      type: { style: "success", icon: true },

    });
  }

  public showError(message: string): void {
    this.notificationService.show({
      content: message ?? '',
      hideAfter: 1500,
      width: 500,
      position: { horizontal: "center", vertical: "top" },
      animation: { type: "slide", duration: 300 },
      type: { style: "error", icon: true },
    });
  }
}
