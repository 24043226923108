import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { LKEducationPlan } from 'src/app/models/education/LKEducationPlan.model';
import { LKEducationProgram } from 'src/app/models/education/LKEducationProgram.model';
import { DictStudyForm } from 'src/app/models/profile/dictionaries/DictStudyForm.model';
import { LKStudEduGroup } from 'src/app/models/profile/lkStudEduGroup.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LKStudEduGroupService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.StudEduGroup}`;

  constructor(private http: HttpClient) { }

  //Get StudEduGroup
  public getStudEduGroup(studentId: string) {
    return this.http.get<LKStudEduGroup>(this.baseUrl + '/GetStudEduGroup/' + studentId)
      .pipe(
        catchError(this.handleError));
  }

  //Get EducationPlan by StudentId
  public getEducationPlanByStudentId(studentId: string) {
    return this.http.get<LKEducationPlan>(this.baseUrl + '/GetEducationPlan/' + studentId)
      .pipe(
        catchError(this.handleError));
  }

  //Get EducationProgram by StudentId
  public getEducationProgramByStudentId(studentId: string) {
    return this.http.get<LKEducationProgram>(this.baseUrl + '/GetEducationProgramByStudentId/' + studentId)
      .pipe(
        catchError(this.handleError));
  }

  //Get StudyForm by StudentId
  public getStudyFormByStudentId(studentId: string) {
    return this.http.get<DictStudyForm>(this.baseUrl + '/GetStudyFormByStudentId/' + studentId)
      .pipe(
        catchError(this.handleError));
  }


  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
