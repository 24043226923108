<div class="container">
    <h1>Портфолио</h1>
    <button kendoButton class="addButton bottom" iconClass="k-icon k-i-plus" (click)="portfolioAddForm()">Добавить</button>
    <kendo-listview
    [data]="directionActivities"
    containerClass="k-d-flex k-flex-col k-flex-nowrap">
    <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-isFirst="isFirst">
        <h2 style="margin-bottom: 30px; margin-top: 20px;">{{dataItem.name}}</h2>
        <h3 [hidden]="!noDataCheck(dataItem.portfolio)">По данному направлению нет ни одного достижения</h3>
        <kendo-grid [data]="dataItem.portfolio" 
        [hidden]="noDataCheck(dataItem.portfolio)"
        (cellClick)="portfolioEditForm($event)"
        (dataStateChange)="onStateChange($event)"
        [kendoGridSelectBy]="dataItem.portfolio"
        scrollable="none">
            <kendo-grid-column field="status" title="Статус" [width]="150" [headerStyle]="{'text-align': 'center'}" [class]="{ codeColumn: true }">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                        <div *ngIf="dataItem.status == 1">{{"Добавлен"}}</div>
                        <div *ngIf="dataItem.status == 2">{{"Одобрен"}}</div>
                        <div *ngIf="dataItem.status == 3">{{"Архивирован"}}</div>
                      </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="name" title="Название мероприятия (работы)" [headerStyle]="{'text-align': 'center'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                        {{ dataItem.name}}
                      </span>
                </ng-template>
            </kendo-grid-column>
            <!--
            <kendo-grid-column field="condition" title="Критерий" [width]="150" [headerStyle]="{'text-align': 'center'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                        <div *ngIf="dataItem.condition == 'null'">
                            {{""}}
                        </div>
                        <div *ngIf="dataItem.condition != 'null'">
                            {{dataItem.condition}}
                        </div>
                      </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="subCondition" title="Подкритерий" [width]="150" [headerStyle]="{'text-align': 'center'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                        <div *ngIf="dataItem.subCondition == 'null'">
                            {{""}}
                        </div>
                        <div *ngIf="dataItem.subCondition != 'null'">
                            {{dataItem.subCondition}}
                        </div>
                      </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="markWithCondition" title="Балл с учетом коэф." [width]="180" [headerStyle]="{'text-align': 'center'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                        {{ dataItem.markWithCondition}}
                      </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="eventDate" title="Дата мероприятия" [width]="180" [headerStyle]="{'text-align': 'center'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                        {{ dataItem.eventDate | date: 'dd.MM.YYYY'}}
                      </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="expirationDate" title="Действует до" [width]="150" [headerStyle]="{'text-align': 'center'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                        {{ dataItem.expirationDate | date: 'dd.MM.YYYY'}}
                      </span>
                </ng-template>
            </kendo-grid-column>
            
            <kendo-grid-column field="notes" title="Примечание" [width]="150" [headerStyle]="{'text-align': 'center'}">
                <ng-template kendoGridCellTemplate let-dataItem >
                    <span class="alignCells">
                        <div *ngIf="dataItem.notes == 'null'">
                            {{""}}
                        </div>
                        <div *ngIf="dataItem.notes != 'null'">
                            {{dataItem.notes}}
                        </div>
                      </span>
                </ng-template>
            </kendo-grid-column>
            -->
            <kendo-grid-column field="review" title="Сканированная копия"[width]="190" [headerStyle]="{'text-align': 'center'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span style="display: block; cursor: pointer; margin: 0 auto" class="k-icon k-i-file" (click)="downloadFile(dataItem)">
                    </span>
                    <!--
                    <div *ngIf="dataItem.review == true" style="text-align: center; display: grid; width: 50%; margin: 0 auto;">
                        <span class="k-icon k-i-file" style="grid-row: 1; cursor: pointer;">
                        </span>
                        <span class="k-icon k-i-eye" style="grid-row: 1; cursor: pointer;"></span>
                    </div>
                    <div *ngIf="dataItem.review == false" style="text-align: center; display: grid; width: 50%; margin: 0 auto;">
                        <span class="k-icon k-i-file" style="grid-row: 1"></span>
                        <span class="k-icon k-i-eye" style="opacity: 0.5; grid-row: 1"></span>
                    </div>
                    -->
                  </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="" [headerStyle]="{'text-align': 'center'}" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <button kendoButton (click)="open(dataItem)"
                    themeColor="secondary"
                    icon="delete"
                    fillMode="flat"
                    size="medium"
                    rounded="full">
                    </button>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </ng-template>
  </kendo-listview>
  <button kendoButton class="addButton bottom" iconClass="k-icon k-i-plus"  (click)="portfolioAddForm()">Добавить</button>
</div>
<kendo-dialog
title="Пожалуйста, подтвердите следующее действие"
*ngIf="opened"
(close)="close()"
[minWidth]="250"
[width]="750"
>
<p style="margin: 30px; text-align: center;">
  Вы уверены что хотите удалить достижение?
</p>
<kendo-dialog-actions>
  <button kendoButton (click)="close()">Нет</button>
  <button kendoButton (click)="deletePortfolio()" themeColor="primary">
    Да
  </button>
</kendo-dialog-actions>
</kendo-dialog>