// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiEndpoint: 'https://tm.evision.tech/',
  trustedNetClientId: '4b4cc31dd32870871947159a0fb1ddee',
  authEndPoint: 'https://auth.nntu.ru',
  fileServerEndpoint: 'https://tm.evision.tech/',
  allowedDomains: ['tm.evision.tech'],
  apiPaths: {
    ExamList: "exam",
    Student: "student",
    DictOrganization: "dictorganization",
    DictStudentAcademicState: "dictstudentacademicstate",
    DictStudentBudget: "dictstudentbudget",
    DictStudentBudgetSubCategory: "dictstudentbudgetsubcategory",
    EduGroup: "edugroup",
    Email: "email",
    Languages: "languages",
    OrdersStudyPeriod: "ordersstudyperiod",
    Phone: "phone",
    StudEduGroup: "studedugroup",
    StudentAcademicState: "studentacademicstate",
    StudentBudgetState: "studentbudgetstate",
    StudPerson: "studperson",
    Personal: "person",
    EducationProgram: "eprogram",
    TimeTable: "timetable",
    StudyProgress: "study",
    EducationIntensityData: "intensity",
    EducationBUP: "bup",
    BudgetTime: "btime",
    DirectionActivity: "directionactivity",
    Portfolio: "portfolio"
  }
};

export const projecting = {
  production: false,
  apiEndpoint: 'https://tm.evision.tech/',
  apiPaths: {
    standard: "projecting/estandard",
    standardtype: "projecting/d/EducationStandardTypes",
    studylevel: "projecting/d/EducationLevels",
    qualification: "projecting/d/Qualifications",
    oop: "projecting/eprogram",
    faculty: "projecting/ext/Faculty",
    kafedra: "projecting/ext/Department",
    trainingLevel: "projecting/d/TrainingLevels",
    dictcycle: "projecting/d/DictCycle",
    dictCycleType: "projecting/d/DictCycleType",
    dictComponent: "projecting/d/DictComponent",
    dictComponentType: "projecting/d/DictComponentType",
    dictLessonType: "projecting/d/DictLessonType",
    dictLessonForm: "projecting/d/DictLessonForm",
    dictAcceleratedStudyBase: "projecting/d/DictAcceleratedStudyBase",
    dictWorkCategorySchedule: "projecting/d/DictWorkCategorySchedule",
    dictWorkScheduleStudyProcessType: "projecting/d/DictWorkScheduleStudyProcessType",
    dictWorkStudyPlanType: "projecting/d/DictWorkStudyPlanType",
    educationPrograms: "projecting/eprogram",
    educationPlan: "projecting/eplan",
    competenceMatrixService: "projecting/martix",
    eStandardTypes: "projecting/d/EducationStandardTypes",
    dictEducationLevel: "projecting/d/EducationLevels",
    dictQualification: "projecting/d/Qualifications",
    dictTrainingLevel: "projecting/d/TrainingLevels"
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
