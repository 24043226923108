import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {

  public gridData = [
    { id: 1, subject: "Психологическое сопровождение пациентов в общей медицинской практике", time: "9:00", date: "23.01.2022", location: "400(III)", fio:"Иванов Иван Иванович" },
    { id: 2, subject: "Психология, психопатология и психотерапия зависимостей", time: "9:00", date: "23.01.2022", location: "400(III)", fio:"Иванов Иван Иванович"  },
    { id: 3, subject: "Типология личностных расстройств", date: "23.01.2022", time: "9:00", location: "400(III)", fio:"Иванов Иван Иванович" },
  ];

  public studentData = [{id: 1, fullName: "Васькин Александр Анатольевич", studentCardNumber: "20002/м", profile:"(01) Клиническая психология", 
  faculty: "N/A", speciality:"N/A", courseNumber: 4, group: "КП-4", academicStatus: "Учится", studentCode: "N/A", email:"vaskin-family@rambler.ru" }];

  public coursesData = [
    {id: 1, name: "1 курс"},
    {id: 2, name: "2 курс"},
    {id: 3, name: "3 курс"},
  ]
  
  constructor() { }

  ngOnInit(): void {
  }

}
