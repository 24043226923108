import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { LKPortfolio } from 'src/app/models/portfolio/portfolio.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LKPortfolioService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.Portfolio}`;
  fileServerUrl = `${environment.fileServerEndpoint}`

  constructor(private http: HttpClient) { }
  progress?: number;
  message?: string;
  @Output() public onUploadFinished = new EventEmitter();

  //Get all LKPortfolio
  public getAllPortfolios(): Observable<LKPortfolio[]> {
    return this.http.get<LKPortfolio[]>(this.baseUrl + '/GetAllPortfolios/')
      .pipe(catchError(this.handleError));
  }

  //Get Portfolios by StudentID
  public getPortfoliosByStudentId(studentId: string) {
    return this.http.get<LKPortfolio[]>(this.baseUrl + '/GetPortfoliosByStudentId/' + studentId)
      .pipe(
        catchError(this.handleError));
  }

  //Get LKPortfolio by Id
  public getLKPortfolioById(id: number) {
    return this.http.get<LKPortfolio>(this.baseUrl + '/GetPortfolioByID/' + id)
      .pipe(
        catchError(this.handleError));
  }

  //Add LKPortfolio
  addLKPortfolio(lKPortfolio: FormData): Observable<LKPortfolio> {
    return this.http.post<LKPortfolio>(this.baseUrl + '/CreatePortfolio/', lKPortfolio)
      .pipe(catchError(this.handleError));
  }

  //Delete LKPortfolio
  deleteLKPortfolio(lKPortfolioId: number) {
    return this.http.delete(this.baseUrl + '/DeletePortfolio/' + lKPortfolioId)
      .pipe(catchError(this.handleError));
  }

  //Edit LKPortfolio
  updateLKPortfolio(lKPortfolio: FormData, lKPortfolioId: number): Observable<LKPortfolio> {
    return this.http.put<LKPortfolio>(this.baseUrl + '/UpdatePortfolio/' + lKPortfolioId, lKPortfolio)
      .pipe(catchError(this.handleError));
  }

  //Download file
  downloadFile(fileName: string) {
    return this.http.get(this.baseUrl + '/DownloadFile/' + fileName, { responseType: 'blob' } )
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}