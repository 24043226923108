import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { DirectionActivity } from 'src/app/models/portfolio/directionActivity.model';
import { LKPortfolio } from 'src/app/models/portfolio/portfolio.model';
import { ScanFile } from 'src/app/models/portfolio/scanFile.model';
import { LKStudent } from 'src/app/models/profile/lkstudent.model';
import { LKStudPerson } from 'src/app/models/profile/lkStudPerson.model';
import { DirectionActivityService } from 'src/app/services/LKPortfolio/direction-activity.service';
import { LKPortfolioService } from 'src/app/services/LKPortfolio/lkportfolio.service';
import { NotificationsService } from 'src/app/services/Notifications/notifications.service';

@Component({
  selector: 'app-portfolio-edit-form',
  templateUrl: './portfolio-edit-form.component.html',
  styleUrls: ['./portfolio-edit-form.component.scss']
})
export class PortfolioEditFormComponent implements OnInit{

  public opened = false;
  public fileUploaded = false;
  public fileName!: string;
  public filesTest?: any = [];
  private idNumber = Number(this.route.snapshot.paramMap.get('id'));

  // StudPerson
  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  // Student
  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "",
    studPersonId: "",
    studPerson: this.studPerson
  }

  public restrictions: FileRestrictions = {
    minFileSize: 10,
    maxFileSize: 4194304,
    allowedExtensions: [".pdf"],
  };

  public checked = false;
  public active = false;
  private editedRowIndex: number | undefined;
  public max: Date = new Date();
  public uploads: Array<any> = [];

  constructor(private route: ActivatedRoute,
    private router: Router, 
    private portfolioService: LKPortfolioService,
    private directionActivitiesService: DirectionActivityService,
    private notificationsService: NotificationsService,
    public cdr: ChangeDetectorRef) { }
  

  ngOnInit(): void {
    this.getDirectionActivities();
    this.getPortfolioById(this.idNumber);
  }

  updateState() {
      this.checked = !this.checked;
  }

  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() save: EventEmitter<LKPortfolio> = new EventEmitter();

  public scanFiles: ScanFile[] = [];
  public scanFile: ScanFile = {
    fileName: "",
  }

  public portfolios!: LKPortfolio[];
  public portfolio: LKPortfolio = {
    portfolioId: 0,
    studentId: "",
    portfolioExternalId: "",
    directionActivityId: "",
    status: "",
    name: "",
    condition: "",
    subCondition: "",
    marksWithCondition: "",
    //eventDate: new Date(),
    //expirationDate: new Date(),
    notes: "",
    review: false,
    scanFile: this.scanFiles
  }

  public directionActivities!: DirectionActivity[];
  public directionActivity: DirectionActivity = {
    directionActivityId: 0,
    directionActivityExternalId: "",
    name: "",
    portfolio: this.portfolios
  }

  public activitiesForm: FormGroup = new FormGroup({
    directionActivityId: new FormControl(),
    directionActivityExternalId: new FormControl(),
    name: new FormControl(),
  })

  public editForm: FormGroup = new FormGroup({
    portfolioId: new FormControl(),
    portfolioExternalId: new FormControl(),
    studentId: new FormControl(),
    directionActivityExternalId: new FormControl(),
    status: new FormControl(),
    name: new FormControl(),
    meetsScholarshipRequirement: new FormControl(),
    condition: new FormControl(),
    subCondition: new FormControl(),
    marksWithCondition: new FormControl(),
    eventDate: new FormControl(),
    expirationDate: new FormControl(),
    notes: new FormControl(),
    review: new FormControl(),
    scanFile: new FormControl()
  });

  onCancel() {
    this.router.navigate(['portfolio']);
  }

  public getDirectionActivities() {
    this.directionActivitiesService.getAllDirectionActivities()
      .subscribe(
        response => {
          this.directionActivities = response;
        }
      )
  }

  public savePortfolio() {
    // В текущей версии пока присваиваем это значение к false
    this.editForm.value.meetsScholarshipRequirement = false;
    
    var formData = new FormData();
    var eventDate = (new Date(this.editForm.value.eventDate)).toUTCString();
    var expirationDate = (new Date(this.editForm.value.expirationDate)).toUTCString();

    formData.append(`PortfolioId`, this.editForm.value.portfolioId)
    formData.append(`PortfolioExternalId`, this.editForm.value.portfolioExternalId)
    formData.append(`DirectionActivityExternalId`, this.activitiesForm.value.directionActivityExternalId)
    formData.append(`StudentId`, this.editForm.value.studentId)
    formData.append(`Status`, this.editForm.value.status)
    formData.append(`Name`, this.editForm.value.name)
    formData.append(`MeetsScholarshipRequirement`, this.editForm.value.meetsScholarshipRequirement)
    formData.append('Condition', this.editForm.value.condition)
    formData.append(`SubCondition`, this.editForm.value.subCondition)
    formData.append(`MarksWithCondition`, this.editForm.value.marksWithCondition)
    formData.append(`EventDate`, eventDate)
    formData.append(`ExpirationDate`, expirationDate)
    formData.append(`Notes`, this.editForm.value.notes)
    formData.append(`Review`, this.editForm.value.review)

    if (this.uploads != undefined) {
      for (let i = 0; i < this.uploads.length; i++) {
          formData.append(`ScanFile[${i}].FileName`, this.uploads[i].name);
          formData.append(`ScanFile[${i}].File`, this.uploads[i], this.uploads[i].name);
      }
    }

    this.portfolioService.updateLKPortfolio(formData, this.editForm.value.portfolioId)
    .subscribe(
      response => {
        this.notificationsService.showSuccess("Достижение успешно изменено!");
        this.onCancel();
      },
      error => {
        this.notificationsService.showError('Не удалось изменить достижение');
      }
    );
  }

  public fileSelected()
  {
    this.fileUploaded = true;
    //console.log(this.uploads);
  }

  public fileRemoved()
  {
    this.fileUploaded = false;
  }

  public downloadFile()
  {
    this.portfolioService.downloadFile(`${this.editForm.value.studentId}
    /${this.editForm.value.directionActivityId}/${this.editForm.value.portfolioExternalId}/${this.editForm.value.scanFile[0].fileName}`);
  }

  public getPortfolioById(id: number) {
    this.portfolioService.getLKPortfolioById(id)
      .subscribe(
        response => {
          this.portfolio = response;
          //console.log(response)
          const eventDate = (new Date(`${this.portfolio.eventDate}`)).toUTCString();
          const expirationDate = (new Date(`${this.portfolio.expirationDate}`)).toUTCString();
          this.editForm = new FormGroup({
            portfolioId: new FormControl(this.portfolio.portfolioId),
            studentId: new FormControl(this.portfolio.studentId),
            status: new FormControl(this.portfolio.status),
            portfolioExternalId: new FormControl(this.portfolio.portfolioExternalId),
            directionActivityId: new FormControl(this.portfolio.directionActivityId),
            name: new FormControl(this.portfolio.name),
            meetsScholarshipRequirement: new FormControl(this.portfolio.meetsScholarshipRequirement),
            condition: new FormControl(this.portfolio.condition),
            subCondition: new FormControl(this.portfolio.subCondition),
            marksWithCondition: new FormControl(this.portfolio.marksWithCondition),
            eventDate: new FormControl(new Date(eventDate)),
            expirationDate: new FormControl(new Date(expirationDate)),
            notes: new FormControl(this.portfolio.notes),
            review: new FormControl(this.portfolio.review),
            scanFile: new FormControl(response.scanFile)
          });
          this.activitiesForm = new FormGroup({
            directionActivityExternalId: new FormControl(this.portfolio.directionActivityId),
          });
          //console.log(response.scanFile);
          //console.log("Response", response)
          this.scanFiles = response.scanFile;
          this.filesTest = response.scanFile;
          for (let i = 0; i < this.filesTest.length; i++) {
            this.filesTest[i].name
            this.filesTest[i].file
          }
          //console.log("Filetest", this.filesTest[0].fileName)
          this.fileName = this.filesTest[0].fileName
          //console.log("Uploads", this.uploads)
          /*if(this.editForm.value.meetsScholarshipRequirement == true)
          {
            this.checked = true;
          }
          else {
            this.checked = false;
          }*/
        }
      )
      //console.log(this.editForm.value)
  }

  public close()
  {
    this.opened = false;

  }
  public open()
  {
    this.opened = true;

  }

}