<div class="container">
    <h2 style="padding-bottom: 6px;">Изменить портфолио</h2>
    <form novalidate class="k-form" [formGroup]="activitiesForm">
        <kendo-formfield>
            <div style="display: flex; align-items: center;">
                <h3 style="width: 100%">Название деятельности</h3>
                <kendo-dropdownlist 
                [data]="directionActivities"
                textField="name"
                valueField="directionActivityExternalId"
                [valuePrimitive]="true"
                [formControl]="$any(activitiesForm).controls['directionActivityExternalId']"
                formControlName="directionActivityExternalId" #directionActivityExternalId required>
                </kendo-dropdownlist>
            </div>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
    </form>
    <form novalidate class="k-form" [formGroup]="editForm">
        <kendo-formfield>
            <div style="display: flex; align-items: center;">
                <h3 style="width: 100%">Название мероприятия (работы)</h3>
                <kendo-textbox formControlName="name" [maxlength]="250" #name required></kendo-textbox>
            </div>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <div style="display: flex; align-items: center;">
                <h3 style="width: 100%">Сканированная копия</h3>
                <kendo-fileselect style="width: 100%" [multiple]="false" (select)="fileSelected()" (remove)="fileRemoved()" [restrictions]="restrictions" [multiple]="false" [(ngModel)]="uploads"
                              [ngModelOptions]="{standalone: true}">
                              <kendo-fileselect-messages
                              dropFilesHere="Перетащите файл"
                              select="Загрузить файл"
                              invalidFileExtension="Недопустимый формат файла. Используйте только .pdf файлы"
                              invalidMaxFileSize="Размер файла должен быть не более 4 МБ"
                              invalidMinFileSize="Слишком малый размер файла">
                          </kendo-fileselect-messages>
                </kendo-fileselect>
            </div>
            <span (click)="downloadFile()" style="display: flex; align-items: center; cursor: pointer" >
                <span class="k-icon k-i-file"></span>
                <h3 style="margin-left: 10px">{{fileName}}</h3>
            </span>
            <h3 style="color: red" *ngIf="!fileUploaded">* Загрузите новую копию для изменения достижения</h3>
        </kendo-formfield>
        <div class="conditionCheckbox" *ngIf="checked">
            <input type="checkbox" formControlName="meetsScholarshipRequirement" #meetsScholarshipRequirement />
            <h3 class="conditionTitle">Соответствует критерию из стипендиального положения</h3>
        </div>
        <div *ngIf="checked">
            <kendo-formfield>
                <h3>Критерий</h3>
                <kendo-textbox formControlName="condition" [maxlength]="50" #condition required></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield>
                <h3>Подкритерий</h3>
                <kendo-textbox formControlName="subCondition" [maxlength]="50" #subCondition required></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield>
                <h3>Балл</h3>
                <kendo-textbox formControlName="marksWithCondition" [maxlength]="50" #marksWithCondition required></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield>
                <h3>Дата мероприятия</h3>
                <kendo-datepicker
                format="dd.MM.yyyy"
                #eventDate
                formControlName="eventDate"
                [max]="max">
                </kendo-datepicker>
            </kendo-formfield>
            <kendo-formfield>
                <h3>Дейстсвует до</h3>
                <kendo-datepicker
                format="dd.MM.yyyy"
                #eventDate
                formControlName="expirationDate"
                [max]="max">
                </kendo-datepicker>
            </kendo-formfield>
        </div>
        <div class="buttons">
            <button kendoButton *ngIf="fileUploaded" [disabled]="!fileUploaded" class="saveButton" (click)="open()">Сохранить</button>
            <button kendoButton [primary]="true" class="cancelButton" (click)="onCancel()">Отмена</button>
        </div>
    </form>
</div>
<kendo-dialog
title="Пожалуйста, подтвердите следующее действие"
*ngIf="opened"
(close)="close()"
[minWidth]="250"
[width]="750"
>
<p style="margin: 30px; text-align: center;">
  Вы уверены что хотите изменить достижение?
</p>
<kendo-dialog-actions>
  <button kendoButton (click)="close()">Нет</button>
  <button kendoButton (click)="savePortfolio()" themeColor="primary">
    Да
  </button>
</kendo-dialog-actions>
</kendo-dialog>