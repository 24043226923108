<div style="margin: 0 -16px">
    <kendo-tilelayout>
        <kendo-tilelayout-item title="Успеваемость">
            <kendo-tilelayout-item-body>
                <div class="dropdownBlock">
                    <div class="dropdownChild">
                        <h3>Выбрать период:</h3>
                    </div>
                    <div class="dropdownChild">
                        <kendo-dropdownlist [data]="coursesData" textField="name" valueField="id" [valuePrimitive]="true">
                        </kendo-dropdownlist>
                    </div>
                </div>
                <h3>Промежуточный контроль</h3>
                <kendo-grid [data]="gridData">
                    <kendo-grid-column field="id" title="№" [width]="100"></kendo-grid-column>
                    <kendo-grid-column field="subject" title="Дисциплина" [width]="100"></kendo-grid-column>
                    <kendo-grid-column field="location" title="Оценка" [width]="80" format="n0">
                    </kendo-grid-column>
                    <kendo-grid-column field="location" title="Балл рейтинга" [width]="80" format="n0"></kendo-grid-column>
                    <kendo-grid-column field="date" title="Дата получения" [width]="80" format="n0">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.date + " " + dataItem.time}}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="fio" title="Пересдачи" [width]="80" format="n0"></kendo-grid-column>
                </kendo-grid>    
            </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
    </kendo-tilelayout>
</div>